@import "../settings";

// logo slider
.logo-slider {

  &__heading {
    background: none !important;
    color: $yellow !important;
    font-family: $body-font-family;
    font-size: rem-calc(16);
    font-weight: $global-weight-normal;
    -webkit-text-fill-color: $yellow !important;

    @include breakpoint(medium) {
      font-size: rem-calc(18);
    }
  }

  // Make it looks like the slides go off screen on mobile.
  .mon-row {
    @include breakpoint(small only) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  // Apply a grid to the image container so we can position the images into the middle of the columns.
  .slider__image {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-items: center;
    // Remove the padding.
    margin: 0;
    padding-top: 0;
    position: relative;

    // Keep the images at their initial state as these will be small logos so we dont want them to pixelate.
    img,
    picture {
      align-self: center;
      grid-column: 1/ -1;
      object-fit: contain;
      position: relative;
      width: auto;
    }
  }

}
